<template>
    <div class="import_detail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-input v-model="form.deptGroupName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input v-model="form.departmentName" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="save" size="small">保存当前页面变更</el-button>
            <span style="margin-left: 200px; color: red">仅保存当前页200条数据</span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border style="width: 100%" :data="tableData">
                <el-table-column label="序号" width="60" type="index" />
                <el-table-column label="会员名称" width="160" prop="name">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="手机号" width="160" prop="mobile">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.mobile" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="等级" width="120" prop="level">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.level" size="mini">
                            <el-option
                                v-for="level in meta.levels"
                                :label="level.name"
                                :value="level.level"
                                :key="level.code"
                            />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="折扣" width="120" prop="discount">
                    <template slot-scope="scope">
                        {{ handleFindDiscount(scope.row.level) }}
                    </template>
                </el-table-column>
                <el-table-column label="付款方式" width="160">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.paymentFlag" filterable size="mini">
                            <el-option value="" label="请选择" />
                            <el-option
                                v-for="payType in meta.payType"
                                :label="payType.template.name"
                                :key="payType.template.flag"
                                :value="payType.template.flag"
                            />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="余额" width="120" prop="money">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.money" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="推荐人手机号" width="120" prop="developMobile">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.developMobile" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteRow(scope.$index, tableData, scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                ref="pagePagination"
                :page-sizes="page.pageSizes"
                :page-size="page.limit"
                :current-page="page.page"
                :layout="page.PageStyle"
                :total="page.total"
                @current-change="handleCurrentChange"
            />
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import Utils from '../../../../js/Util';
import MoneyUtils from 'js/MoneyUtils.js';

export default {
    name: 'ImportEdit',
    props: ['form'],
    data() {
        return {
            tableData: [],
            discount: '',
            card: {
                statusList: '',
                delCodes: [],
                cardType: this.form.cardType,
                bizCode: this.form.code,
                deptGroupCode: this.form.deptGroupCode,
            },
            page: {
                page: 1,
                limit: 200,
                total: 0,
                pageSizes: Utils.PageSizes,
                PageStyle: 'prev, pager, next,total,jumper',
            },
            meta: {
                payType: [],
                levels: [],
            },
            url: {
                page: '/member/import/detail/page',
                save: '/member/import/patchExtend/',
                queryPayType: '/finance/pay/payType/page',
                queryDeptGroupLevel: '/member/level/queryDeptGroupMemberLevel',
            },
            codes: [],
            changePage: false,
            changePageData: { page: 1, limit: 200 },
        };
    },
    mounted() {
        this.handleChangeDept();
        this.handleLevel();
        this.handleQuery();
    },
    methods: {
        handleFindDiscount(val) {
            for (let j = 0; j < this.meta.levels.length; j++) {
                const level = this.meta.levels[j];
                if (val === level.level) {
                    return level.discount;
                }
            }
        },
        save() {
            const _this = this;
            const _array = [];
            const newData = [];
            let indexs = '';
            this.tableData.forEach((value, index) => {
                _array.push(value.code);
                if (Utils.isEmpty(value.paymentFlag)) {
                    this.$message.error('请选择付款方式');
                    return false;
                }
                if (Utils.isEmpty(value.name)) {
                    this.$message.error('请输入名称');
                    return false;
                }
                if (Utils.isEmpty(value.mobile)) {
                    this.$message.error('请输入手机号');
                    return false;
                }
                const old = value.oldData;
                const oldValue =
                    old.code +
                    old.name +
                    String(old.mobile) +
                    String(old.level || '') +
                    String(MoneyUtils.moneyToDb(old.money || 0)) +
                    (old.paymentFlag || '') +
                    String(old.developMobile || '');
                const newValue =
                    value.code +
                    value.name +
                    value.mobile +
                    String(value.level || '') +
                    String(MoneyUtils.moneyToDb(value.money || 0)) +
                    (value.paymentFlag || '') +
                    String(value.developMobile || '');
                if (!Object.is(oldValue, newValue)) {
                    newData.push(value);
                    indexs = indexs + String(index + 1) + '、';
                }
            });
            let _message = '保存当前页面的数据变动';
            if (newData.length > 0) {
                indexs = indexs.slice(0, indexs.length - 1);
                _message = '保存当前页面的第【' + indexs + '】行数据变动';
            }
            this.codes.forEach((value) => {
                if (!_array.includes(value)) {
                    this.card.delCodes.push(value);
                }
            });
            if (newData.length > 0 || _this.card.delCodes.length > 0) {
                const _params = {
                    message: _message,
                    codes: newData.map((d) => d.code),
                    names: newData.map((d) => d.name),
                    mobiles: newData.map((d) => d.mobile),
                    levels: newData.map((d) => d.level),
                    paymentFlags: newData.map((d) => d.paymentFlag),
                    moneys: newData.map((d) => MoneyUtils.moneyToDb(d.money || 0)),
                    developMobiles: newData.map((d) => d.developMobile || ''),
                    delCodes: _this.card.delCodes,
                    cancelMethod: function () {
                        _this.changeOrBackPage();
                    },
                };
                UrlUtils.PatchRemote(
                    this,
                    this.url.save + _this.form.code,
                    _params,
                    null,
                    () => {
                        _this.$message.success('当前页面的修改已变更成功');
                        _this.changePage = true;
                        _this.changeOrBackPage();
                    },
                    () => {
                        _this.changeOrBackPage();
                    }
                );
            } else {
                _this.changePage = true;
                _this.changeOrBackPage();
            }
        },
        deleteRow(index, rows, row) {
            this.tableData.splice(index, 1);
        },
        handleChangeDept() {
            const _this = this;
            if (_this.form.deptCode) {
                const _params = { params: { enable: true, deptCode: _this.form.deptCode } };
                Utils.queryTable(_this, _this.url.queryPayType, _params, (data) => {
                    data.data = data.data || [];
                    data.data.forEach((v, i) => {
                        if (v.template.canRecharge) {
                            this.meta.payType.push(v);
                        }
                    });
                });
            }
        },
        handleCurrentChange(page) {
            this.changePage = false;
            this.changePageData.page = page;
            this.save();
        },
        changeOrBackPage() {
            if (this.changePage) {
                this.page.page = this.changePageData.page;
                this.card.delCodes = [];
                this.handleQuery();
            } else {
                this.$refs.pagePagination.$data.internalCurrentPage = this.page.page;
            }
        },
        handleLevel() {
            const _this = this;
            const _params = { params: { deptGroup: _this.form.deptGroupCode } };
            Utils.queryTable(_this, _this.url.queryDeptGroupLevel, _params, (data) => {
                _this.meta.levels = data.data;
            });
        },
        handleQuery() {
            const _this = this;
            _this.codes = [];
            const _params = { params: { bizCode: _this.form.code, page: this.page.page, limit: _this.page.limit } };
            Utils.queryTable(_this, _this.url.page, _params, (data) => {
                _this.tableData = data.data;
                _this.page.total = data.count;
                data.data.forEach((v) => {
                    v.money = (v.money / 10000).toFixed(2);
                    const oldData = Object.assign({}, v);
                    v.oldData = oldData;
                    _this.codes.push(v.code);
                });
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
